import React from 'react';
import './App.css';
import { FaTwitter, FaYoutube } from 'react-icons/fa';
import { SiX } from 'react-icons/si'; // Xのマークを示す場合

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import CreatorPage from './pages/CreatorPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import AboutPage from './pages/AboutPage';
import SoundsPage from './pages/SoundsPage';

function App() {
  const location = useLocation();
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="App background-image">
      <header className="App-header">
      <img src={`${process.env.PUBLIC_URL}/239top.png`} alt="header" className="header-image" />
        <div className="navigation">
          <Link to="/" className="mainbutton">HOME</Link>
          <Link to="/sounds/bgm" className="mainbutton">SOUNDS</Link>
          <Link to="/creators" className="mainbutton">CREATORS</Link>
          <Link to="/terms-of-service" className="mainbutton">TERMS</Link>
        </div>

        <TransitionGroup>
          <CSSTransition
            key={location.pathname} // 修正箇所
            classNames="page"
            timeout={500}
          >
            <Routes location={location}>
              <Route path="/" element={<AboutPage />} />
              <Route path="/sounds" element={<SoundsPage />} />
              <Route path="/creators" element={<CreatorPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/terms-of-service" element={<TermsOfServicePage />} />

              <Route path="/sounds/bgm" element={<SoundsPage />} />
              <Route path="/sounds/se" element={<SoundsPage />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </header>

      <footer className="footer-container">
      <div>
        <div className="footer-links">
          <a
            href="https://twitter.com/fmc_239"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link-icon"
          >
            <SiX size={20} />
          </a>
          <a
            href="https://www.youtube.com/@FreeMusicCreators239"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link-icon"
          >
            <FaYoutube size={20} />
          </a>
        </div>
        <div className="copyright">
          © FreeMusicCreators. All rights reserved.
        </div>
      </div>
    </footer>

    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}