// src/data.js

export const songs = [
  {
    "ReleaseDate": "2024/09/01",
    "title": "Get",
    "info": "アイテムを取得した時のようなSE音です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/Get.mp3",
    "tags": [
      "se",
      "ゲーム",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/10/27",
    "title": "pop end",
    "info": "可愛い感じのOPやED等で使えそうな短い曲です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/popend.mp3",
    "tags": [
      "jingle",
      "op",
      "ed",
      "tony",
      "new"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "Technology",
    "info": "都市が発展していくような雰囲気をイメージした短めの曲です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/Technology.mp3",
    "tags": [
      "jingle",
      "op",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "UP!",
    "info": "Youtubeの動画OP用で使えそうな短めの曲です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/UP!.mp3",
    "tags": [
      "jingle",
      "op",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "ドラムロール1",
    "info": "ドラムロールのSE音です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/%E3%83%89%E3%83%A9%E3%83%A0%E3%83%AD%E3%83%BC%E3%83%AB1.mp3",
    "tags": [
      "se",
      "楽器",
      "ドラム",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "ドラムロール2",
    "info": "ちょっと遊び多めのドラムロールのSE音です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/%E3%83%89%E3%83%A9%E3%83%A0%E3%83%AD%E3%83%BC%E3%83%AB2.mp3",
    "tags": [
      "se",
      "楽器",
      "ドラム",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "ドラムロール3",
    "info": "ちょっと遊び多めのドラムロールのSE音(2パターン目)です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/%E3%83%89%E3%83%A9%E3%83%A0%E3%83%AD%E3%83%BC%E3%83%AB3.mp3",
    "tags": [
      "se",
      "楽器",
      "ドラム",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "はい問題",
    "info": "問題出題の時のようなSE音です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/%E3%81%AF%E3%81%84%E5%95%8F%E9%A1%8C.mp3",
    "tags": [
      "se",
      "効果音",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "マイクラ風殴る音",
    "info": "マイクラで殴るようなSE音です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/%E3%83%9E%E3%82%A4%E3%82%AF%E3%83%A9%E9%A2%A8%E6%AE%B4%E3%82%8B%E9%9F%B3.mp3",
    "tags": [
      "se",
      "ゲーム",
      "効果音",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "閑散な住宅街の雨音",
    "info": "静かな住宅街で収録した雨音です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/%E9%96%91%E6%95%A3%E3%81%AA%E4%BD%8F%E5%AE%85%E8%A1%97%E3%81%AE%E9%9B%A8%E9%9F%B3.mp3",
    "tags": [
      "se",
      "自然",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/09/01",
    "title": "祭",
    "info": "祭りの騒がしい感じを声多めで表現している短め曲です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/music/%E7%A5%AD.mp3",
    "tags": [
      "jingle",
      "ゲーム",
      "声あり",
      "tony"
    ]
  },
  {
    "ReleaseDate": "2024/11/24",
    "title": "歪み",
    "info": "短めの荒ぶるかっこいいCMのような雰囲気をイメージした曲です",
    "youtubeEmbedUrl": "se",
    "streamingUrl": "",
    "downloadUrl": "https://fmcmusicdata.s3.ap-northeast-1.amazonaws.com/se-etc/%E6%AD%AA%E3%81%BF.mp3",
    "tags": [
      "jingle",
      "op",
      "ed",
      "ゲーム",
      "tony",
      "new"
    ]
  }
];
